<template>
  <app-dialog
    v-if="organizationWallet"
    v-model="model"
    data-cy-missing-credits-dialog
    :title="title"
    :width="700"
  >
    <div class="flex items-center gap-4 rounded bg-error-50 p-4 text-error">
      <app-icon icon="ph:coins" :size="30" />

      <div class="flex-auto">
        <p>Votre solde de crédit est insuffisant.</p>

        <p class="text-sm">
          <strong>{{
            $t("credit.amount_label", {
              count: request.credits,
            })
          }}</strong>
          nécessaires sur
          <strong>{{
            $t("credit.amount_label", {
              count: organizationWallet.balance,
            })
          }}</strong>
          disponible.
        </p>
      </div>
    </div>

    <p class="mb-2 mt-6 text-sm font-semibold">
      Votre contact privilégié pour acheter de nouveaux crédits
    </p>

    <support-contact-overview />

    <template #footer>
      <div class="grid place-content-end">
        <app-button :href="callScheduleUrl"> Prendre rendez-vous </app-button>
      </div>
    </template>
  </app-dialog>
</template>

<script lang="ts" setup>
import type { RegistrationRequestView } from "~/business-areas/registration-request/registration-request.model";

const properties = defineProps<{
  modelValue?: boolean;
  request: RegistrationRequestView;
}>();

const model = useVModel(properties, "modelValue");

const { organizationWallet } = useCurrentOrganizationWalletStore();

const title = computed(
  () =>
    `Impossible d'accepter la demande de ${properties.request.member.user.full_name}`,
);

const {
  public: {
    hubspot: { callScheduleUrl },
  },
} = useRuntimeConfig();
</script>
